<template>
  <div class="sales-overview-container">
    <my-nav-bar
      title="销售总览"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-cell-group>
        <van-cell title="日期" :value="date" is-link title-class="date-cell-title-css" value-class="date-cell-value-css" @click="handleSelecteDate" />
      </van-cell-group>
      <van-cell-group v-if="salesStatistics" title="营业数据">
        <van-cell value-class="amount" title="订单金额" :value="'￥' + salesStatistics.order_amount" />
        <van-cell v-if="salesStatistics.red_packet_amount < 0" value-class="amount" title="扣减红包" :value="'￥' + salesStatistics.red_packet_amount" />
        <van-cell v-if="salesStatistics.coupon_amount < 0" value-class="amount" title="扣减优惠券" :value="'￥' + salesStatistics.coupon_amount" />
        <van-cell v-if="salesStatistics.after_amount < 0" value-class="amount" title="扣减退货" :value="'￥' + salesStatistics.after_amount" />
        <van-cell value-class="amount" title="应收" :value="'￥' + salesStatistics.sale_amount" />
        <van-cell v-if="salesStatistics.unpaid_amount < 0" value-class="amount" title="扣减签单" :value="'￥' + salesStatistics.unpaid_amount" />
        <van-cell v-if="salesStatistics.decimal_amount < 0" value-class="amount" title="扣减尾数" :value="'￥' + salesStatistics.decimal_amount" />
        <van-cell value-class="amount" title="定金" :value="'￥' + salesStatistics.deposit_amount" />
        <van-cell value-class="amount" title="实收(含定金)" :value="'￥' + salesStatistics.real_amount" />
      </van-cell-group>
      <van-cell-group v-if="salesStatistics && salesStatistics.unpaid_amount < 0" title="签单数据">
        <van-cell v-if="salesStatistics.unpaid_after_amount < 0" value-class="amount" title="签单退货" :value="'￥' + salesStatistics.unpaid_after_amount" />
        <van-cell value-class="amount" title="实际签单" :value="'￥' + salesStatistics.real_unpaid_amount" />
      </van-cell-group>
      <van-cell-group v-if="salesStatistics" title="采购数据">
        <van-cell value-class="amount" title="来货入库" :value="'￥' + salesStatistics.accept_order_amount" is-link center @click="toAcceptOrderView" />
      </van-cell-group>
      <van-cell-group v-if="salesStatistics" title="其它收款">
        <van-cell value-class="amount" title="签单收款" :value="'￥' + salesStatistics.unpaid_receive_amount" center is-link @click="toUnpaidReceiveView" />
        <van-cell value-class="amount" title="欠条收款" :value="'￥' + salesStatistics.iou_receive_amount" center />
      </van-cell-group>
    </div>
    <van-popup v-model="showPopup" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        @cancel="showPopup = false"
        @confirm="selecteDate"
      />
    </van-popup>
  </div>
</template>

<script>
import { now, toDateString } from '@/utils'
import { getSalesStatistics } from '@/api/statistics'
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'SalesOverview',
  components: { myNavBar },
  data() {
    return {
      date: now('YYYY-MM-DD'),
      salesStatistics: null,
      showDatePick: false,
      showPopup: false,
      currentDate: new Date()
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.beginLoad()
      getSalesStatistics({ date: this.date }).then(res => {
        this.endLoad()
        this.salesStatistics = res.data
      })
    },
    handleSelecteDate() {
      this.showPopup = true
    },
    selecteDate() {
      this.date = toDateString(this.currentDate)
      this.getData()
      this.showPopup = false
    },
    toAcceptOrderView() {
      this.$router.push({
        path: 'total-data-accept',
        query: {
          is_employee: 1,
          time: this.date
        }
      })
    },
    toUnpaidReceiveView() {
      this.$router.push({
        path: 'total-data-unpaid-receive',
        query: {
          type: 'day',
          time: this.date
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import 'src/styles/color';
  .sales-overview-container {
    .amount {
      color: $amountRedColor;
    }
    .date-cell-value-css{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .date-cell-title-css{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span{
        line-height:normal;
      }
    }
  }
</style>
