var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sales-overview-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "销售总览",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('van-cell-group', [_c('van-cell', {
    attrs: {
      "title": "日期",
      "value": _vm.date,
      "is-link": "",
      "title-class": "date-cell-title-css",
      "value-class": "date-cell-value-css"
    },
    on: {
      "click": _vm.handleSelecteDate
    }
  })], 1), _vm.salesStatistics ? _c('van-cell-group', {
    attrs: {
      "title": "营业数据"
    }
  }, [_c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "订单金额",
      "value": '￥' + _vm.salesStatistics.order_amount
    }
  }), _vm.salesStatistics.red_packet_amount < 0 ? _c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "扣减红包",
      "value": '￥' + _vm.salesStatistics.red_packet_amount
    }
  }) : _vm._e(), _vm.salesStatistics.coupon_amount < 0 ? _c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "扣减优惠券",
      "value": '￥' + _vm.salesStatistics.coupon_amount
    }
  }) : _vm._e(), _vm.salesStatistics.after_amount < 0 ? _c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "扣减退货",
      "value": '￥' + _vm.salesStatistics.after_amount
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "应收",
      "value": '￥' + _vm.salesStatistics.sale_amount
    }
  }), _vm.salesStatistics.unpaid_amount < 0 ? _c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "扣减签单",
      "value": '￥' + _vm.salesStatistics.unpaid_amount
    }
  }) : _vm._e(), _vm.salesStatistics.decimal_amount < 0 ? _c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "扣减尾数",
      "value": '￥' + _vm.salesStatistics.decimal_amount
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "定金",
      "value": '￥' + _vm.salesStatistics.deposit_amount
    }
  }), _c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "实收(含定金)",
      "value": '￥' + _vm.salesStatistics.real_amount
    }
  })], 1) : _vm._e(), _vm.salesStatistics && _vm.salesStatistics.unpaid_amount < 0 ? _c('van-cell-group', {
    attrs: {
      "title": "签单数据"
    }
  }, [_vm.salesStatistics.unpaid_after_amount < 0 ? _c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "签单退货",
      "value": '￥' + _vm.salesStatistics.unpaid_after_amount
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "实际签单",
      "value": '￥' + _vm.salesStatistics.real_unpaid_amount
    }
  })], 1) : _vm._e(), _vm.salesStatistics ? _c('van-cell-group', {
    attrs: {
      "title": "采购数据"
    }
  }, [_c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "来货入库",
      "value": '￥' + _vm.salesStatistics.accept_order_amount,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": _vm.toAcceptOrderView
    }
  })], 1) : _vm._e(), _vm.salesStatistics ? _c('van-cell-group', {
    attrs: {
      "title": "其它收款"
    }
  }, [_c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "签单收款",
      "value": '￥' + _vm.salesStatistics.unpaid_receive_amount,
      "center": "",
      "is-link": ""
    },
    on: {
      "click": _vm.toUnpaidReceiveView
    }
  }), _c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "欠条收款",
      "value": '￥' + _vm.salesStatistics.iou_receive_amount,
      "center": ""
    }
  })], 1) : _vm._e()], 1), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showPopup,
      callback: function callback($$v) {
        _vm.showPopup = $$v;
      },
      expression: "showPopup"
    }
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "date"
    },
    on: {
      "cancel": function cancel($event) {
        _vm.showPopup = false;
      },
      "confirm": _vm.selecteDate
    },
    model: {
      value: _vm.currentDate,
      callback: function callback($$v) {
        _vm.currentDate = $$v;
      },
      expression: "currentDate"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }